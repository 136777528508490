import { useState } from 'react';
import './Timetable.css';

// Double table row
const Dtr = ({first, second, idSecond}) => {
	return (
		<tr>
			<td>{first}</td>
			<td id={idSecond ? idSecond : ''}>{second}</td>
		</tr>
	)
}

const Timetable = () => {
	const [hetki, setHetki] = useState('∞');

	// IIFE arrow function
	// Aka executes immediately
	(() => {
		const startDate = new Date("Dec 12, 2018 23:59:59");

    setInterval(function() {
      var now = new Date();
      var distance = now - startDate;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // For future usage
      setHetki("Myöhässä: " + days + "d " + hours + "h " + minutes + "m " + seconds + "s");
    }, 1000);
	})();

	return (
		<div>
			<h2>Kapanen - Suomi sanakirja</h2>

			<table>
				<thead>
					<tr>
						<th>Kapasen aika</th>
						<th>Oikea aika</th>
					</tr>
				</thead>
				<tbody>
					<Dtr first="Kohta" second="2h 40min"/>
					<Dtr first="Pian" second="1-3 päivää"/>
					<Dtr first="Hetki" second={hetki} idSecond="countdown"/>
					<Dtr first="10min" second="1 Dota matsi"/>
					<Dtr first="Syön eka" second="1h 30min"/>
					<Dtr first="Syön nopeesti" second="2 päivää 8 tuntia"/>
					<Dtr first="Teen leipää" second="38min"/>
					<Dtr first="Keskiviikko" second="Ensi viikon maanantai"/>
					<Dtr first="Kolmen maissa" second="15.30"/>
					<Dtr first="Kuuen maissa" second="19.25"/>
					<Dtr first="Vähä myöhässä" second="2h 20min"/>
					<Dtr first="Shoppailemaan 2-3 maissa" second="5, kun kaikki kaupat on kiinni"/>
					<Dtr first="Haen kaapin nopeeta(???)" second="1h 26min"/>
					<Dtr first="About 22" second="22.45"/>
				</tbody>
			</table>

		</div>
	);
}

export default Timetable;
