import styles from "./Thesis.module.css";
import { useState } from "react";

import vangelis from "../assets/vangelis.mp3";
import trailer from "../assets/jojo.mp4";

const Phases = {
	Initial: "Initial",
	Timer: "Timer",
	Final: "Final"
};

const InitialTime = "0d 0h 5m 0s";

// 5 minutes
const duration = 300000;

// Set the date we're counting down to
// Originally "May 29, 2022 00:00:00"
let countDownDate;

function downloadURI(uri, name) 
{
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

const Thesis = () => {
	const [timer, setTimer] = useState(InitialTime);
	const [currentPhase, setCurrentPhase] = useState(Phases.Initial);

	const yes = () => {
		document.getElementById('joo').play();

		// Update the count down every 1 second
		let x = setInterval(function () {

			// Get today's date and time
			const now = new Date().getTime();

			// Find the distance between now and the count down date
			const distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			// Display the result in the element with id="demo"
			setTimer(days + "d " + hours + "h "
				+ minutes + "m " + seconds + "s ");

			// If the count down is finished, write some text
			if (distance < 0) {
				clearInterval(x);
				document.getElementsByTagName("body")[0].style.background = "black";

				setCurrentPhase(Phases.Final);
				setTimeout(() => {
					downloadURI("/files/dippa.pdf", "Packaging complex web client in easily embeddable solution")
				}, 1000 * 3)
				
			}
		}, 1000);

		setCurrentPhase(Phases.Timer);

		// 1 extra second due to delay displaying the value.
		// Without this the seconds would jump at the beginning.
		countDownDate = new Date().getTime() + duration + 1;
	}

	return (
		<>
			{currentPhase === Phases.Initial && <>
				<h2>Hype meni jo May 29, 2022 00:00:00!</h2>
				<button id="nappi" onClick={yes}>Download tumo.exe</button>
			</>}

			{currentPhase === Phases.Timer && <>
				<h1 id="hype">DIPLOMITYÖN PALJASTUS</h1>
				<h1 id="demo">{timer}</h1>

				<video id="kapanen" width="100%" height="100%" autoPlay muted loop>
					<source src={trailer} type="video/mp4" />
				</video>
			</>}

			{currentPhase === Phases.Final && <>
				<div className={styles.fullscreenblack}></div>
				<p className={styles.flashing}>HYPE<br />IS REAL</p>
			</> }

			<audio id="joo" src={vangelis} loop hidden></audio>
		</>
	)
}

export default Thesis;