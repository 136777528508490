import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import config from "../utils/config";

const Navbar = () => {
	return (
		<nav>
			<ul className="topnav">
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<Link to="/kapanen">Timetable</Link>
				</li>
				<li>
					<Link to="/recruiting">Recruiting</Link>
				</li>
				<li className="dropdown">
					<div className="dropbtn">Discord Bot</div>
					<div className="dropdown-content">
						<a
							href={config.EXTERNAL_URLS.KOWALSKI}
							target="_blank"
							rel="noopener noreferrer">
							Kowalski
						</a>
						<a
							href={config.EXTERNAL_URLS.KOWALSKIBETA}
							target="_blank"
							rel="noopener noreferrer">
							Kowalski BETA
						</a>
					</div>
				</li>
			</ul>
		</nav>
	)
}

export default Navbar;