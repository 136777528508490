const NoPage = () => {
	return (
		<div>
			<h2>404</h2>
			<h2>Not Found :(</h2>
		</div>
	);
}

export default NoPage;
