import duck from '../assets/rubber-duck-construction.png'
import quack from '../assets/quack.weba'
import elevatorMusic from '../assets/elevator.weba'
import doge1 from '../assets/doge_miner1.png'
import doge2 from '../assets/doge_miner2.png'

import './DuckGame.css';
import { useState } from 'react';

const DuckGame = () => {
	const [clicks, setClicks] = useState(0);
	const [tier, setTier] = useState(0);
	const [doge, setDoge] = useState(doge1);
	const [greeter, setGreeter] = useState('UNDER KONSTRUKSIÖN');

	// Cost required to upgrade.
	// The first few upgrades are free and require only clicks.
	const upgradeCosts = [0, 0, 100, 2, 2500];
	const freeUpgradesRequiredClicks = [10, 50];

	const handleClick = () => {
		const audio = new Audio(quack);
		audio.play();

		if (freeUpgradesRequiredClicks.includes(clicks + 1)) {
			upgrade();
		}

		// Update applies later so we can't
		// use the value of clicks AFTER the update consistently.
		setClicks(clicks + 1);
		if (doge === doge1) {
			setDoge(doge2);
		}
		else {
			setDoge(doge1);
		}
	}

	const upgrade = () => {
		console.log(tier + 1)
		switch (tier) {
			case 0:
				setGreeter(`So you want to play, huh? Get to ${freeUpgradesRequiredClicks[1]} clicks.`)
				break;
			case 1:
				setGreeter("Welcome.. TO DUCK GAME!");
				break;
			case 2:
				const bgAudio = new Audio(elevatorMusic);
				bgAudio.loop = true;
				bgAudio.play();
				break;
			case 3:
				break;
			default:
				break;
		}

		setTier(tier + 1);
	}

	const buyUpgrade = () => {
		console.log("upgrading..", tier);
		const cost = upgradeCosts[tier];
		if (cost <= clicks) {
			setClicks(clicks - cost);
			upgrade();
		}
		console.log(cost);
	}

	return (
		<div className="App">
			<p className="Clicker">{clicks}</p>
			<img src={duck} className="App-logo" alt="logo" onClick={handleClick} />
			<img src={doge} className="topleft" alt="" />
			<img src={doge} className="topright" alt="" />
			<img src={doge} className="bottomleft" alt="" />
			<img src={doge} className="bottomright" alt="" />
			{tier >= 2 &&
				<div>
					<button onClick={buyUpgrade}>
						Upgrade
					</button>
				</div>
			}
			<p>
				{greeter}
			</p>
			<a
				className="App-link"
				href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
				target="_blank"
				rel="noopener noreferrer"
			>
				While You Wait
			</a>
		</div>
	);
}

export default DuckGame;
