import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import config from './utils/config';
import Layout from './components/Layout';
import DuckGame from './components/DuckGame';
import Timetable from './components/Timetable';
import Recruiting from './pages/Recruiting';
import NoPage from './pages/NoPage';
import Thesis from './pages/Thesis';
import KapanenHype from './pages/KapanenHype';
import Redirect from './components/Redirect';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route index element={<DuckGame />} />
							<Route path="kapanen" element={<Timetable />} />
							<Route path="recruiting" element={<Recruiting />} />
							<Route path="dippa" element={<Thesis />} />
							<Route path="hype" element={<KapanenHype />} />
							<Route path="crypto" element={<Redirect to={config.EXTERNAL_URLS.CRYPTO} />} />
							<Route path="kowalski" element={<Redirect to={config.EXTERNAL_URLS.KOWALSKI} />} />
							<Route path="kowalskibeta" element={<Redirect to={config.EXTERNAL_URLS.KOWALSKIBETA} />} />
							<Route path="*" element={<NoPage />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</header>
		</div>
	);
}

export default App;
