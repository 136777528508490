import styles from "./Thesis.module.css";
import { useState } from "react";

import vangelis from "../assets/vangelis.mp3";
import trailer from "../assets/jojo.mp4";

const Phases = {
	Initial: "Initial",
	Timer: "Timer",
	Final: "Final"
};

const InitialTime = "Loading..";

// Set the date we're counting down to
// Originally "May 29, 2022 00:00:00"
let countDownDate = new Date(2023, 2, 28, 21, 38);


const KapanenHype = () => {
	const [timer, setTimer] = useState(InitialTime);
	const [currentPhase, setCurrentPhase] = useState(Phases.Initial);
	const [traitor, setTraitor] = useState({text: "Pettää :(", count: 0});

	const traitorClicked = () => {
			let newText;
			if(traitor.count === 0) {
				newText = "KYLLÄPÄS PETTÄÄ!!";
				alert('KAPANEN EI PETÄ!!!');
			}		
			else {
				window.open('https://www.youtube.com/watch?v=1o3_6XMnFWU', '_blank')
				newText = "Kapanen ei petä";
			}
			

			setTraitor({
				text: newText,
				count: traitor.count + 1
			})
	}

	const yes = () => {
		document.getElementById('joo').play();

		// Update the count down every 1 second
		setInterval(function () {

			// Get today's date and time
			const now = new Date().getTime();

			// Find the distance between now and the count down date
			const distance =  now - countDownDate;

			// Time calculations for days, hours, minutes and seconds
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			// Display the result in the element with id="demo"
			setTimer(days + "d " + hours + "h "
				+ minutes + "m " + seconds + "s ");

		}, 1000);

		setCurrentPhase(Phases.Timer);
	}

	return (
		<>
			{currentPhase === Phases.Initial && <>
				<h2>Kapanen..</h2>
				<button id="nappi" onClick={yes}>Ei petä!</button>
				<button onClick={traitorClicked}>{traitor.text}</button>
			</>}

			{currentPhase === Phases.Timer && <>
				<h1 id="hype">"PITÄÄ HUOMENNA LAITTAA"</h1>
				<h1 id="demo">{timer}</h1>

				<video id="kapanen" width="100%" height="100%" autoPlay muted loop>
					<source src={trailer} type="video/mp4" />
				</video>
			</>}

			{currentPhase === Phases.Final && <>
				<div className={styles.fullscreenblack}></div>
				<p className={styles.flashing}>HYPE<br />IS REAL</p>
			</> }

			<audio id="joo" src={vangelis} loop hidden></audio>
		</>
	)
}

export default KapanenHype;